import React, { Suspense, useState } from 'react';
import Header from '~/components/Header';
import AboutQuizzly from '~/components/AboutQuizzly';
import Testimonials from '~/components/Testimonials';
import ReadyToInstall from '~/components/ReadyToInstall';

import 'lazysizes';

import '../styles/index.scss';

// const Header = React.lazy(() => import('../components/Header'));
// const AboutQuizzly = React.lazy(() => import('../components/AboutQuizzly'));
// const Testimonials = React.lazy(() => import('../components/Testimonials'));
const OurClients = React.lazy(() => import('../components/OurClients'));
const DetailedInfo = React.lazy(() => import('../components/DetailedInfo'));
const WhyQuizzly = React.lazy(() => import('../components/WhyQuizzly'));
// const Advantages = React.lazy(() => import('../components/Advantages'));
// const ReadyToInstall = React.lazy(() => import('../components/ReadyToInstall'));
const Footer = React.lazy(() => import('../components/Footer'));
const Modal = React.lazy(() => import('../components/Modal'));

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <Suspense>
        <Header setModalIsOpen={setModalIsOpen} />
        <AboutQuizzly />
        <Testimonials />
        <OurClients />
        <DetailedInfo />
        <WhyQuizzly />
        {/*<Advantages />*/}
        <ReadyToInstall setModalIsOpen={setModalIsOpen} />
        <Footer setModalIsOpen={setModalIsOpen} />
        <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      </Suspense>
      <script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        data-domain-script="c9f19c12-39ba-450a-b87a-fad3537b0b50"
        defer
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5G82XW4Q');
      `,
        }}
      ></script>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-5G82XW4Q"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
    </>
  );
};

export default Home;
